<nz-layout style="height:100%;">
  <!--<nz-header *ngIf="!isAuthenticated">
    <div nz-row>
      <div nz-col nzFlex="600px">
        <img class="logo" src="../assets/Logo.png" (click)="goHome()" />
        <ul *ngIf="!isAuthenticated" nz-menu nzTheme="light" nzMode="horizontal">
          <li nz-menu-item>Games</li>
          <li nz-menu-item>About</li>
          <li nz-menu-item>Contact</li>
        </ul>
        <div nz-row *ngIf="isAuthenticated">
          <div nz-col nzFlex="3">
            <p>Player: {{player?.firstName}} {{player?.lastName}}</p>
          </div>
          <div nz-col nzFlex="2">
            <p>Balance: {{player?.freeEntries}}pts</p>
          </div>
        </div>
      </div>
      <div nz-col nzFlex="auto">

      </div>
      <div nz-col nzFlex="100px" *ngIf="!isLogin">
        <button *ngIf="!isAuthenticated" nz-button nzType="default" (click)="login()"><i nz-icon nzType="login"></i>Login</button>
        <button *ngIf="isAuthenticated" nz-button nzType="default" (click)="openDrawer()"><i nz-icon nzType="menu"></i>Menu</button>
      </div>
    </div>
  </nz-header>-->
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
  <!--<nz-sider *ngIf="isAuthenticated" nzTheme="light" nzCollapsible [(nzCollapsed)]="menuClosed" [nzReverseArrow]="true" [nzWidth]="120">
    <div><img class="logoSider" src="../assets/Logo.png" (click)="goHome()" /></div>
    <ul nz-menu nzTheme="light" nzMode="inline">
      <li nz-menu-item routerLink="/GameFrame"><i nz-icon nzType="crown"></i><span>Games</span></li>
      <li nz-menu-item routerLink="/Purchase"><i nz-icon nzType="shopping-cart"></i><span>Buy</span></li>
      <li nz-menu-item routerLink="/Redeem"><i nz-icon nzType="dollar"></i><span>Redeem</span></li>
      <li nz-menu-item (click)="logout()"><i nz-icon nzType="logout"></i><span>Logout</span></li>
    </ul>
  </nz-sider>-->
<label style="align-self:end;margin:10px">VERSION 1.0.0.2</label>
</nz-layout>
